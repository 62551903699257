import React from "react";
import { Buffer } from "buffer";
import ReactDOM from "react-dom/client";
import App from "./MoonbaseMint/MoonbaseMint";
import ReactGA from "react-ga4";

// Node polyfills required by WalletConnect are no longer bundled with webpack
window.Buffer = Buffer;
ReactGA.initialize("G-X44WN6TH8N");

const root = ReactDOM.createRoot(document.getElementById("root") as any);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
