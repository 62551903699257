import React, { FC, useEffect } from "react";

// ↓↓↓ Don't forget to import the widgets' fonts! ↓↓↓
import "@uniswap/widgets/fonts.css";
// ↑↑↑
import styles from "../styles/Home.module.css";
import GlobalStyle from "../shared/ui/GlobalStyles";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";

import { DesktopOrMobileNavBar } from "../navbar/DesktopOrMobileNavBar";
import { AppContextProvider } from "../hooks/context";
import styled from "styled-components";
import { Maintenance } from "../components/Maintenance";
import { TxSubmittedModal } from "../components/TxSubmittedModal";
import ReactGA from "react-ga4";
import { MintWidget } from "../components/MintWidget";
import { WagmiProvider } from "wagmi";
import { wagmiConfig } from "../wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const App: FC = () => {
  const queryClient = new QueryClient();
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
    console.log("sent analytics");
  });
  if (process.env.REACT_APP_PUBLIC_MAINTENANCE === "true") {
    return <Maintenance />;
  } else {
    return (
      <Wrapper>
        <GlobalStyle />
        <WagmiProvider config={wagmiConfig}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider>
              <AppContextProvider>
                <DesktopOrMobileNavBar />
                <TxSubmittedModal />
                <main className={styles.main}>
                  <div className={styles.demo}>
                    <MintWidget />
                  </div>
                </main>
                <Footer>
                  <div>
                    <a
                      href="https://www.apolloftw.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ApolloFTW.com
                    </a>{" "}
                    •
                    <a
                      href="https://www.apolloftw.com/apollo-team-nfts-terms-of-service/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms Of Service
                    </a>
                  </div>
                </Footer>
              </AppContextProvider>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </Wrapper>
    );
  }
};

export default App;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Footer = styled.footer`
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  padding: 0.5rem;

  a {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    padding: 0.75rem;
    text-decoration: none;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;
