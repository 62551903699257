import styled from "styled-components";
import { FC } from "react";
import { useAccount } from "wagmi";

export const MintSubLabel: FC = () => {
  const { isConnected } = useAccount();
  if (isConnected) {
    return (
      <Label>
        Mint up to 10 at a time
        <br /> Each Mint is .1 Eth + gas fee
      </Label>
    );
  } else {
    return <Label>Mint up to 10</Label>;
  }
};

const Label = styled.h2`
  font-weight: 400;
  font-size: 12px;
  color: #72796f;
  text-align: center;
  margin: 0;
`;
