import { BigNumber } from "@ethersproject/bignumber";
import styled, { css } from "styled-components";
import { FC, useState } from "react";
import { Buffer } from "buffer";

import {
  GOERLI_CHAIN_ID,
  GOERLI_NAME,
  MAINNET_CHAIN_ID,
  MAINNET_NAME,
  NFT_CONTRACT_ABI,
  NFT_MAINNET_ADDRESS,
} from "../constants";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";

export const MintOrConnectButton: FC = () => {
  const { address, isConnected, chainId } = useAccount();
  const [mintAmount, setMintAmount] = useState<string>("");
  const [validMintAmount, setValidMintAmount] = useState<boolean>(false);

  const { writeContract, isPending, data: hash } = useWriteContract();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });
  const valueNumber = BigNumber.from(
    process.env.REACT_APP_NFT_MINT_COST as string
  );
  const finalValue = validMintAmount
    ? valueNumber.mul(Number.parseInt(mintAmount))
    : BigNumber.from(0);

  const totalText = !validMintAmount
    ? "0"
    : Number.parseInt(mintAmount) < 10
    ? "." + mintAmount.toString()
    : "1";

  const mint = async () => {
    if (!window.Buffer) {
      window.Buffer = Buffer;
    }
    console.log("valueNumber is: " + valueNumber);
    console.log("mint amount for calc is: " + mintAmount);
    console.log("finalValue is: " + finalValue);
    const abi = NFT_CONTRACT_ABI;
    writeContract({
      abi,
      address: (process.env.REACT_APP_NFT_CONTRACTADDRESS ||
        NFT_MAINNET_ADDRESS) as `0x${string}`,
      functionName: "mint",
      args: [address, mintAmount],
      value: finalValue.toBigInt(),
    });
    //const tx = await contract.mint(address, mintAmount, { value: finalValue });
  };
  //const regex1to11 = new RegExp(/^[1-9]\d*$/);
  const validateMintAmount = (value: string) => {
    console.log("Mint amount is " + value);
    const num = Number.parseInt(value);
    if (Number.isInteger(num)) {
      console.log("Valid mint string is: " + value);
      const num = Number.parseInt(value);
      if (num < 11 && num > 0) {
        console.log("Valid mint number is: " + value);
        setMintAmount(num.toString());
        setValidMintAmount(true);
      } else {
        console.log("invalid mint number is: " + value);
        setValidMintAmount(false);
      }
    } else {
      console.log("invalid mint string is: " + value);
      setValidMintAmount(false);
    }
  };

  const desiredChainID =
    process.env.REACT_APP_CHAIN_ID != null
      ? process.env.REACT_APP_CHAIN_ID
      : GOERLI_CHAIN_ID;

  if (!isConnected) {
    return <ConnectButton />;
  }
  if (desiredChainID !== chainId?.toString()) {
    const networkName =
      desiredChainID === MAINNET_CHAIN_ID.toString()
        ? MAINNET_NAME
        : GOERLI_NAME;
    return (
      <StyledMintButton disabled={true}>
        Change Network to {networkName}
      </StyledMintButton>
    );
  } else if (isPending) {
    return (
      <>
        <StyledMintButton disabled={true}>Tx Submitted</StyledMintButton>
        {hash && <div>Transaction Hash: {hash}</div>}
        {isConfirming && <div>Waiting for confirmation...</div>}
      </>
    );
  } else {
    return (
      <>
        <InputContainer>
          <TextField
            className="text-input"
            type="text"
            placeholder="Amount"
            onChange={(e) => validateMintAmount(e.target.value)}
          />
          <Label>Total price is {totalText} Eth</Label>
        </InputContainer>
        <StyledMintButton
          disabled={!validMintAmount}
          onClick={mint}
          validAmount={validMintAmount}
        >
          Mint
        </StyledMintButton>
        {isConfirmed && <div>Transaction confirmed.</div>}
      </>
    );
  }
};

interface DepositButtonItemProps {
  validAmount?: boolean;
}
const StyledMintButton = styled.button<DepositButtonItemProps>`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  background-color: ${(props) => (props.validAmount ? "#68ff85" : "#d0d5dd")};
  border-style: none;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    opacity: 0.85;
    cursor: pointer;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const Label = styled.h2`
  font-weight: 400;
  font-size: 12px;
  color: #72796f;
  text-align: center;
  padding: 8px;
  margin: 0;
`;

export const textFieldCSS = css`
  padding: 10px 14px;

  border-width: 1px;
  border-style: solid;
  border-color: #d0d5dd;
  border-radius: 8px;

  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  color: #101828;
  transition: box-shadow 200ms ease, border-color 200ms ease;

  ::placeholder {
    color: #667085;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(104, 255, 133, 0.2);
    border-color: #68ff85;
  }
`;

const TextField = styled.input`
  ${textFieldCSS}
  width: 40%;
  height: 100%;
`;
