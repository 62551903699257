import { FC, useContext } from "react";
import { Modal } from "./Modal";
import styled from "styled-components";
import { AppContext } from "../hooks/context";
import { setTxSubmittedModal } from "../hooks/state";
import {
  GOERLI_CHAIN_ID,
  TX_CONFIRMED_BUTTON_TEXT,
  TX_CONFIRMED_TEXT,
  TX_CONFIRMED_TITLE_TEXT,
  TX_SUBMITTED_BUTTON_TEXT,
  TX_SUBMITTED_TEXT,
  TX_SUBMITTED_TITLE_TEXT,
} from "../constants";
import { useAccount } from "wagmi";

export const TxSubmittedModal: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { address, chainId } = useAccount();
  const isShown = state.txSubmittedModal != null;
  const shouldClose = () => dispatch(setTxSubmittedModal(null));

  let helpText = "";
  let buttonText = "";
  let titleText = "";

  if (state.txSubmittedModal?.txStatus) {
    helpText = TX_SUBMITTED_TEXT;
    buttonText = TX_SUBMITTED_BUTTON_TEXT;
    titleText = TX_SUBMITTED_TITLE_TEXT;
  } else {
    helpText = TX_CONFIRMED_TEXT;
    buttonText = TX_CONFIRMED_BUTTON_TEXT;
    titleText = TX_CONFIRMED_TITLE_TEXT;
  }
  let URL = getURL(
    chainId?.toString(),
    state.txSubmittedModal?.txStatus,
    state.txSubmittedModal?.modalTxHash,
    address
  );

  return (
    <Modal
      contentWidth="520px"
      isShown={isShown}
      shouldClose={shouldClose}
      title={titleText}
    >
      <ModalContent>
        <HelpText>{helpText}</HelpText>
        <StyledActionButton
          onClick={() => window.open(URL, "_blank", "noreferrer")}
        >
          {buttonText}
        </StyledActionButton>
      </ModalContent>
    </Modal>
  );
};

const getURL = (
  chainId: string | undefined,
  waitingOnFonfirmation: boolean | undefined,
  txHash: string | undefined,
  account: string | undefined
): string => {
  if (waitingOnFonfirmation) {
    const testChain = chainId === GOERLI_CHAIN_ID.toString() ? "goerli." : "";
    return "https://" + testChain + "etherscan.io/tx/" + txHash;
  } else {
    const testChain = chainId === GOERLI_CHAIN_ID.toString() ? "testnets." : "";
    return "https://" + testChain + "opensea.io/" + account;
  }
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 0.5rem 1rem;

  img {
    width: 100%;
    height: auto;
  }
`;

const HelpText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 145%;
  color: #79809d;
  margin: 0 0 1rem;
`;

const StyledActionButton = styled.button`
  flex-shrink: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #002106;
  padding: 8px 14px;
  background-color: #68ff85;
  border-style: none;
  border-radius: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover,
  &:focus {
    opacity: 0.85;
    cursor: pointer;
  }
`;
