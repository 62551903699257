import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  goerli,
  mainnet,
  sepolia
} from 'wagmi/chains';

export const wagmiConfig = getDefaultConfig({
  appName: 'Apollo FTW Mint',
  projectId: '8abefe9c6aa0ec3a6001a9e9e31846f1',
  chains: [
    mainnet,
    goerli,
    sepolia
  ],
});